import React from 'react';
import PropTypes from 'prop-types';

const Dribbble = ({ fill }) => (
	<svg viewBox='0 0 97.75 97.75' fill={fill} xmlns='http://www.w3.org/2000/svg' role='img'>
		<g>
			<path d='M46.927,38.775C42.032,30.117,36.8,22.816,35.746,21.367c-8.416,4.016-14.719,11.764-16.74,21.088 c0.084,0,0.184,0,0.299,0C22.121,42.455,33.789,42.229,46.927,38.775z' />
			<path d='M69.01,25.984C63.628,21.266,56.582,18.4,48.875,18.4c-2.37,0-4.676,0.273-6.893,0.789 c1.242,1.678,6.471,8.9,11.243,17.631C63.539,32.92,68.159,27.148,69.01,25.984z' />
			<path d='M57.713,45.988c0.208,0.488,0.41,0.975,0.609,1.459c2.365-0.291,4.725-0.404,6.967-0.404 c6.951,0,12.768,1.088,14.141,1.365c-0.117-7.033-2.627-13.494-6.753-18.607c-1.067,1.383-6.229,7.498-16.925,11.904 C56.439,43.115,57.098,44.547,57.713,45.988z' />
			<path d='M50.969,49.018c0.336-0.108,0.676-0.207,1.016-0.307c-0.728-1.633-1.512-3.268-2.336-4.879 c-13.565,4.027-26.731,4.342-30.522,4.342c-0.35,0-0.617-0.002-0.801-0.006c-0.006,0.252-0.013,0.504-0.013,0.754 c0.001,7.752,2.91,14.834,7.693,20.223C27.297,67.012,35.695,53.951,50.969,49.018z' />
			<path d='M48.875,0C21.882,0,0,21.883,0,48.875S21.882,97.75,48.875,97.75S97.75,75.867,97.75,48.875S75.868,0,48.875,0z M48.875,85.004c-19.948,0-36.176-16.207-36.177-36.129C12.7,28.953,28.927,12.748,48.875,12.746 c19.948,0.002,36.175,16.207,36.176,36.129C85.05,68.797,68.823,85.004,48.875,85.004z' />
			<path d='M60.327,52.631c3.801,10.484,5.448,19.082,5.856,21.432c6.692-4.608,11.461-11.811,12.84-20.141 c-1.16-0.354-6.157-1.752-12.543-1.752C64.535,52.17,62.46,52.303,60.327,52.631z' />
			<path d='M54.184,54.143c-16.742,5.826-23.078,17.236-23.95,18.949c5.164,3.979,11.628,6.352,18.642,6.352 c4.183,0,8.172-0.844,11.806-2.37c-0.49-2.843-2.269-12.013-6.464-22.943C54.206,54.135,54.195,54.139,54.184,54.143z' />
		</g>
	</svg>
);

Dribbble.defaultProps = {
	fill: 'currentColor'
};

Dribbble.propTypes = {
	fill: PropTypes.string
};

export default Dribbble;
